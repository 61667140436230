<template>
  <div class="page">
    <div class="first">
      <div class="bloc">
        <div class="columns">
          <div class="column is-1">
            <p class="arrow">
              <router-link to="/">
                <svg-icon
                  name="arrow"
                  class="h-8 cursor-pointer"
                  original
                  color="white"
                />
              </router-link>
            </p>
          </div>
          <div class="column intro">
            <h1 class="title">{{ allCgu.title }}</h1>
            <p class="rema_title mt-2">{{ allCgu.secondTitle }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="bloc1 relative">
      <div class="second">
        <div class="columns">
          <div class="column">

              <p><em>{{ allCgu.presentation }}</em></p><br>
            <div v-for="(item, index) in allCgu.articles" :key="index">
              <h1 class="objet">{{ item.title }}</h1>
              <p v-html="item.content" class="objet_dev"></p><br>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div>
<!--      <footerView />-->
    </div>

  </div>
</template>

<script>
import fleche from '@/assets/icons/arrow.svg'
// import footerView from "../home/footerView.vue";
import cgu from './cgu.json'
export default {
  components: { },
  name: 'index-TermsOfService.vue',
  data () {
    return {
      arrow: fleche,
      allCgu: cgu
    }
  }
}
</script>

<style  scoped>
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700,800,900&display=swap');
.page {
  font-family: 'Roboto', sans-serif;
  width: 100%;
}
.first {
  background-color: #16b853;
  text-align: center;
  color: white;
  height: 20em;
}
.bloc {
  padding: 1.5em 8em;
}
p.arrow {
  width: 1.5em;
}
.title {
  color: white;
  font-weight: 400;
  font-size: 3em;
  margin-bottom: 0;
  padding-top: 1em;
}
p.rema_title {
  font-size: 1em;
}
.bloc1 {
  margin-left: 16em;
  margin-right: 16em;
  background-color: white;
  z-index: 99;
  top: -7em;
  box-shadow: -2px 5px 20px 0px rgba(185, 185, 185, 0.25), 0 10px 5px 0 rgba(0, 0, 0, 0);
}
.second {
  padding: 2em 1em;
}
.objet {
  font-size: 1.5em;
  padding-bottom: 1em;
}
.objet_dev {
  font-weight: 300;
}
h2 {
  margin-top: -1em;
  padding-bottom: 1em;
}
.bloc6 {
  background-color: #16B853;
  padding: 5em 8em;
  color: white;
}
.footer_title {
  font-weight: 600;
  font-size: 1em;
}
.infos {
  font-size: 0.9em;
  font-weight: 300;
}
.infos a {
  color: white;
}
@media only screen and (max-width: 600px) {
  .page {
    width: 100%;
    overflow: hidden;
  }
  .first {
    background-color: #16b853;
    text-align: center;
    color: white;
    height: 26em;
  }
  .bloc {
    padding: 1.5em 1em;
  }
  p.arrow {
    width: 1.5em;
  }
  .title {
    color: white;
    font-weight: 400;
    font-size: 2.5em;
    margin-bottom: 0;
    padding-top: 0;
  }
  p.rema_title {
    font-size: 1em;
  }
  .bloc1 {
    margin-left: 1em;
    margin-right: 1em;
    background-color: white;
    z-index: 99;
    top: -6em;
    box-shadow: 0 14px 14px 0 rgba(0,0,0,.25), 0 10px 5px 0 rgba(0,0,0,.22);
  }
  .second {
    padding: 2em 1em;
  }
  .objet {
    font-size: 1.5em;
    padding-bottom: 1em;
  }
  .objet_dev {
    font-weight: 300;
  }
  h2 {
    margin-top: -1em;
    padding-bottom: 1em;
  }
  .bloc6 {
    background-color: #16B853;
    padding: 5em 1em;
    color: white;
  }
  .footer_title {
    font-weight: 600;
    font-size: 1em;
  }
  .infos {
    font-size: 0.9em;
    font-weight: 300;
  }
  .infos a {
    color: white;
  }
}
@media only screen and (min-width: 600px) and (max-width: 768px) {
  .page {
    width: 100%;
    overflow: hidden;
  }
  .first {
    background-color: #16b853;
    text-align: center;
    color: white;
    height: 26em;
  }
  .bloc {
    padding: 1.5em 1em;
  }
  p.arrow {
    width: 1.5em;
  }
  .title {
    color: white;
    font-weight: 400;
    font-size: 3em;
    margin-bottom: 0;
    padding-top: 0;
  }
  p.rema_title {
    font-size: 1em;
  }
  .bloc1 {
    margin-left: 1em;
    margin-right: 1em;
    background-color: white;
    z-index: 99;
    top: -12em;
    box-shadow: 0 14px 14px 0 rgba(0,0,0,.25), 0 10px 5px 0 rgba(0,0,0,.22);
  }
  .second {
    padding: 2em 1em;
  }
  .objet {
    font-size: 1.5em;
    padding-bottom: 1em;
  }
  .objet_dev {
    font-weight: 300;
  }
  h2 {
    margin-top: -1em;
    padding-bottom: 1em;
  }
  .bloc6 {
    background-color: #16B853;
    padding: 5em 1em;
    color: white;
  }
  .footer_title {
    font-weight: 600;
    font-size: 1em;
  }
  .infos {
    font-size: 0.9em;
    font-weight: 300;
  }
  .infos a {
    color: white;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1215px) {
  .page {
    width: 100%;
    overflow: hidden;
  }
  .first {
    background-color: #16b853;
    text-align: center;
    color: white;
    height: 20em;
  }
  .bloc {
    padding: 1.5em 4em;
  }
  p.arrow {
    width: 1.5em;
  }
  .title {
    color: white;
    font-weight: 400;
    font-size: 3em;
    margin-bottom: 0;
    padding-top: 1em;
  }
  p.rema_title {
    font-size: 1em;
  }
  .bloc1 {
    margin-left: 8em;
    margin-right: 8em;
    background-color: white;
    z-index: 99;
    top: -7em;
    box-shadow: 0 14px 14px 0 rgba(0,0,0,.25), 0 10px 5px 0 rgba(0,0,0,.22);
  }
  .second {
    padding: 2em 1em;
  }
  .objet {
    font-size: 1.5em;
    padding-bottom: 1em;
  }
  .objet_dev {
    font-weight: 300;
  }
  h2 {
    margin-top: -1em;
    padding-bottom: 1em;
  }
  .bloc6 {
    background-color: #16B853;
    padding: 5em 4em;
    color: white;
  }
  .footer_title {
    font-weight: 600;
    font-size: 1em;
  }
  .infos {
    font-size: 0.9em;
    font-weight: 300;
  }
  .infos a {
    color: white;
  }
}
@media only screen and (min-width: 1216px) and (max-width: 1407px) {
  .page {
    font-family: 'Roboto', sans-serif;
    width: 100%;
  }
  .first {
    background-color: #16b853;
    text-align: center;
    color: white;
    height: 20em;
  }
  .bloc {
    padding: 1.5em 8em;
  }
  p.arrow {
    width: 1.5em;
  }
  .title {
    color: white;
    font-weight: 400;
    font-size: 3em;
    margin-bottom: 0;
    padding-top: 1em;
  }
  p.rema_title {
    font-size: 1em;
  }
  .bloc1 {
    margin-left: 16em;
    margin-right: 16em;
    background-color: white;
    z-index: 99;
    top: -7em;
    box-shadow: 0 14px 14px 0 rgba(0,0,0,.25), 0 10px 5px 0 rgba(0,0,0,.22);
  }
  .second {
    padding: 2em 1em;
  }
  .objet {
    font-size: 1.5em;
    padding-bottom: 1em;
  }
  .objet_dev {
    font-weight: 300;
  }
  h2 {
    margin-top: -1em;
    padding-bottom: 1em;
  }
  .bloc6 {
    background-color: #16B853;
    padding: 5em 8em;
    color: white;
  }
  .footer_title {
    font-weight: 600;
    font-size: 1em;
  }
  .infos {
    font-size: 0.9em;
    font-weight: 300;
  }
  .infos a {
    color: white;
  }
}
@media only screen and (min-width: 1408px) {
  .page {
    font-family: 'Roboto', sans-serif;
    width: 100%;
  }
  .first {
    background-color: #16b853;
    text-align: center;
    color: white;
    height: 20em;
  }
  .bloc {
    padding: 1.5em 8em;
  }
  p.arrow {
    width: 1.5em;
  }
  .title {
    color: white;
    font-weight: 400;
    font-size: 3em;
    margin-bottom: 0;
    padding-top: 1em;
  }
  p.rema_title {
    font-size: 1em;
  }
  .bloc1 {
    margin-left: 16em;
    margin-right: 16em;
    background-color: white;
    z-index: 99;
    top: -7em;
    box-shadow: 0 14px 14px 0 rgba(0,0,0,.25), 0 10px 5px 0 rgba(0,0,0,.22);
  }
  .second {
    padding: 2em 1em;
  }
  .objet {
    font-size: 1.5em;
    padding-bottom: 1em;
  }
  .objet_dev {
    font-weight: 300;
  }
  h2 {
    margin-top: -1em;
    padding-bottom: 1em;
  }
  .bloc6 {
    background-color: #16B853;
    padding: 5em 8em;
    color: white;
  }
  .footer_title {
    font-weight: 600;
    font-size: 1em;
  }
  .infos {
    font-size: 0.9em;
    font-weight: 300;
  }
  .infos a {
    color: white;
  }
}
</style>
